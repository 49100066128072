body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.svgicon {
  width: 22px;
  height: 22px;
  color: #005A82;
}

.muicon {
  /* color: #000000; */
  color: #048BC2;
}

.LinkTo {
  text-decoration: none;
  /* color: inherit; */
  color: #00A0E3;
  padding: 0px 10px;
  font-size: 14px;
  font-weight: bolder;
}

.LinkTo:hover,
.LinkTo:active,
.CustMenuButton:hover {
  /* color: #ffff00; */
  /* color: #672C90 /* Green Theme * / */
  /*color: #ED1C24  Red Theme */
  color: #42AA46;
}

.menulink {
  text-decoration: none;
  color: #1976d2;
  display: flex;
}

/* .pageTitle{
  text-align: center;
}

.pageTitleText{
  font-size: 1.5rem;
  font-weight: bolder;
  color: #EF7F1A;
}
 */
/* 
.pageTitleLeft{
  text-align: left;
} */

/* .pageTitleTextLeft{
  font-size: 1.5rem;
  font-weight: bolder;
  color: #EF7F1A;
} */

/* .MuiAppBar-colorPrimary{
  color: #42AA46;
} */

/* .headingOfPage, body{
  background-image: url('./png/logo5.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  width: 100%; 
  height: 100%;
} */

/* .MuiCheckbox-colorPrimary.Mui-checked{ 
  color: #42AA46;
} */
